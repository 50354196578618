import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { BACKEND_URL } from "../config.js";
import Tweet from "./tweet";
import Article from "./article";
import { getAuthHeaders, isTweetURL, isValidHttpUrl, sanitizeHtmlAndConvertToBase64 } from "../utils/utils.js";
import { safeParseJson } from "./utils/parse.js";
import { useHasX2AdminExtension } from "./extension/hooks/useHasX2AdminExtension";
import { ExtensionToggler } from "./extension/components/ExtensionToggler";
import { useX2AdminContext } from "./extension/context/useX2AdminContext";
import "./breakingNews.scss";

// axios.defaults.headers['ngrok-skip-browser-warning'] = '1';

const PER_PAGE = 3;
const MAX_PAGINATION_PAGES = 10;
const DEFAULT_ERROR =
    "Invalid URL format. Supported format for articles: https://example.com/?freespoke_id=123, for tweets: https://twitter.com/SpaceX/status/1315743656716853253";

function BreakingNews() {
    const alert = useAlert();
    const { user } = useAuth();
    const [passedURLValidation, setPassedURLValidation] = useState(true);
    const [newContent, setNewContent] = useState({ url: "" });
    const [breakingNews, setBreakingNews] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [contentTotal, setContentTotal] = useState(0);
    const [renderCount, forceRender] = useState(0);
    const isMounted = useRef(false);
    const currentPage = useRef(1);

    const { extractMetadataWithExtension } = useHasX2AdminExtension();
    const { enableX2Admin } = useX2AdminContext();

    const fetchBreakingNews = useCallback(async () => {
        try {
            const page = currentPage.current;
            setIsFetching(true);
            const headers = getAuthHeaders(user?.access_token);
            const params = { perPage: PER_PAGE, page: currentPage.current };
            const result = await axios.get(`${BACKEND_URL}/admin/breaking_news`, { headers, params });
            if (isMounted.current && page === currentPage.current) {
                setBreakingNews(result.data.data);
                setContentTotal(result.data.total);
            }
        } catch (e) {
            console.error(e);
            alert.show(`Error: ${e.message || "something went wrong while fetching breaking news"}`, { timeout: 1000, type: "error" });
        } finally {
            setIsFetching(false);
        }
    }, [alert, user.access_token]);

    useEffect(() => {
        isMounted.current = true;
        fetchBreakingNews();
        return () => {
            isMounted.current = false;
        };
    }, [fetchBreakingNews]);

    const saveNewBreakingNewsContent = useCallback(async () => {
        try {
            const headers = getAuthHeaders(user.access_token);

            // Edge case when curator passes a raw json that represents tweet in the URL field
            const tweetAsJson = safeParseJson(newContent.url);
            if (tweetAsJson) {
                await axios.post(`${BACKEND_URL}/admin/breaking_news`, { tweet: tweetAsJson }, { headers });
                return;
            }

            const payload = { ...newContent };

            if (enableX2Admin) {
                try {
                    const extractedData = await extractMetadataWithExtension(newContent.url);
                    console.log("htmlObj:", extractedData);
                    if (extractedData?.html) {
                        payload.rawData = sanitizeHtmlAndConvertToBase64(extractedData.html);
                        if (extractedData?.response?.headers) {
                            payload.sourceHeaders = extractedData.response.headers;
                        }
                    }
                    if (extractedData?.tweet) {
                        payload.tweet = extractedData.tweet;
                    }
                } catch (e) {
                    console.error(`saveNewBreakingNewsContent: ${e}`);
                }
            }

            await axios.post(`${BACKEND_URL}/admin/breaking_news`, payload, { headers });
        } catch (e) {
            console.error(e);
            alert.show(`Error: ${e.message || "something went wrong"}`, { timeout: 1000, type: "error" });
        } finally {
            setIsFetching(false);
        }
    }, [alert, user.access_token, newContent, extractMetadataWithExtension, enableX2Admin]);

    const onChange = useCallback(
        e => {
            const updatedNewContent = { ...newContent, [e.target.name]: e.target.value, createdAt: new Date() };

            const validURL = isValidHttpUrl(updatedNewContent.url);
            const isTweet = isTweetURL(updatedNewContent.url);
            const jsonParsed = safeParseJson(updatedNewContent.url);

            if (isTweet) {
                updatedNewContent.tweet_id = updatedNewContent.url.split("/status/")[1];
                if (updatedNewContent.tweet_id.includes("?")) {
                    updatedNewContent.tweet_id = updatedNewContent.tweet_id.split("?")[0];
                }
            } else {
                updatedNewContent.tweet_id = null;
            }
            ReactDOM.unstable_batchedUpdates(() => {
                setNewContent(updatedNewContent);
                if (validURL || jsonParsed) {
                    setPassedURLValidation(true);
                } else {
                    setPassedURLValidation(false);
                }
            });
        },
        [newContent]
    );

    const addNewContent = useCallback(() => {
        if (!newContent.url) return;

        setIsFetching(true);

        saveNewBreakingNewsContent()
            .then(() => {
                fetchBreakingNews();
                setNewContent({ url: "" });
            })
            .catch(err => alert.show(`Error: ${err.message || "something went wrong"}`, { timeout: 1000, type: "error" }))
            .finally(() => setIsFetching(false));
    }, [alert, fetchBreakingNews, newContent.url, saveNewBreakingNewsContent]);

    const updateContent = useCallback(
        async (_, data, content_id, type) => {
            try {
                if (!content_id) {
                    console.error("updateContent: content_id is not provided");
                    return;
                }
                if (!type) {
                    console.error("updateContent: type is not provided");
                    return;
                }

                const headers = getAuthHeaders(user.access_token);
                const body = {
                    ...data,
                    type
                };
                await axios.patch(`${BACKEND_URL}/admin/breaking_news/${content_id}`, body, { headers });
                alert.show("Saved", { timeout: 1000, type: "success" });
                fetchBreakingNews();
            } catch (e) {
                console.error(e);
                alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
            }
        },
        [alert, fetchBreakingNews, user.access_token]
    );

    const updateTweet = useCallback(
        async (content_id, data) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                const body = {
                    ...data,
                    type: "tweet"
                };
                await axios.patch(`${BACKEND_URL}/admin/breaking_news/${content_id}`, body, { headers });
                alert.show("Saved", { timeout: 1000, type: "success" });
                fetchBreakingNews();
            } catch (e) {
                console.error(e);
                alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
            }
        },
        [alert, fetchBreakingNews, user.access_token]
    );

    const deleteContent = useCallback(
        async contentId => {
            try {
                if (!contentId) {
                    return;
                }
                setIsFetching(true);
                const headers = getAuthHeaders(user.access_token);
                await axios.delete(`${BACKEND_URL}/admin/breaking_news/${contentId}`, { headers });
                fetchBreakingNews();
            } catch (e) {
                console.error(e);
                alert.show(`Error: ${e.message || "something went wrong"}`, { timeout: 1000, type: "error" });
            } finally {
                setIsFetching(false);
            }
        },
        [alert, fetchBreakingNews, user.access_token]
    );

    const onPaginationClick = useCallback(
        e => {
            currentPage.current = parseInt(e.currentTarget.innerHTML);
            fetchBreakingNews();
            forceRender(renderCount + 1);
        },
        [fetchBreakingNews, renderCount]
    );

    const pagination = useMemo(() => {
        const result = [];
        for (let i = 1; i <= Math.min(Math.ceil(contentTotal / PER_PAGE), MAX_PAGINATION_PAGES); i++) {
            result.push(
                <li key={i} className={`page-item ${i === currentPage.current ? "active" : ""}`}>
                    <span onClick={onPaginationClick} className="page-link pointer" href="#" data-testid="page-item">
                        {i}
                    </span>
                </li>
            );
        }
        return result;
    }, [contentTotal, onPaginationClick]);

    return (
        <div className="breaking-news pt-lg-2 mb-4 mr-1">
            <h4 className="text-center">
                Breaking News
                {!isFetching ? (
                    <span onClick={fetchBreakingNews} className="mx-3 pointer text-primary">
                        <i className="fas fa-sync-alt"></i>
                    </span>
                ) : (
                    <span className="mx-3 pointer">
                        <i className="fa fa-refresh fa-spin" data-testid="breaking-news-refresh-spinner" aria-hidden="true"></i>
                    </span>
                )}
            </h4>
            <div className="card bn-body p-3" data-cid="BreakingNewsSection">
                <div key="new" className="input-group mt-2 mb-3">
                    <input
                        type="url"
                        onChange={onChange}
                        value={newContent.url}
                        data-type="pin-new-content"
                        name="url"
                        className={`form-control ${!passedURLValidation ? "is-invalid" : ""}`}
                        data-testid="add-breaking-news-url"
                        placeholder="Add article or Tweet URL"
                    />
                    <div className="invalid-feedback">{DEFAULT_ERROR}</div>
                    {passedURLValidation ? (
                        <div onClick={addNewContent} className="input-group-append pointer">
                            <span className="input-group-text" data-testid="append-breaking-news-url">
                                <i className="fas fa-plus text-success"></i>
                            </span>
                        </div>
                    ) : null}
                    <div className="ml-1 w-100">
                        <ExtensionToggler />
                    </div>
                </div>
                {breakingNews.length ? (
                    <div>
                        <ul className="pagination pagination-sm" data-testid="breaking-news-pagination">
                            {pagination}
                        </ul>
                    </div>
                ) : null}
                {breakingNews.length
                    ? breakingNews.map((content, i) => {
                          if (content.type === "tweet") {
                              return (
                                  <Tweet
                                      key={content._id}
                                      parentId={content._id}
                                      tweet={content.tweet}
                                      index={i}
                                      isBreakingNews={true}
                                      parentData={content}
                                      onUpdate={updateTweet}
                                      onDelete={deleteContent}
                                  />
                              );
                          } else if (content.type === "article") {
                              return (
                                  <Article
                                      key={content._id}
                                      parentId={content._id}
                                      article={content.article}
                                      isBreakingNews={true}
                                      index={i}
                                      isSatire={false}
                                      parentData={content}
                                      onUpdate={updateContent}
                                      onDelete={deleteContent}
                                  />
                              );
                          } else return null;
                      })
                    : null}
            </div>
        </div>
    );
}

export default BreakingNews;
