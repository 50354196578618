import { useEffect, useState } from "react";
import { isValidHttpUrl } from "../../../utils/utils";

// Extension IDs
const EXTENSION_ID = {
    chrome: "hcijikahmefeodlmfpbbigkiljakcdce", // For Chrome/Edge
    firefox: "x2admin@freespoke.com" // For Firefox
};

export const useHasX2AdminExtension = () => {
    const [extensionAvailable, setExtensionAvailable] = useState<boolean | null>(null);

    useEffect(() => {
        checkExtensionAvailability();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Helper for Firefox extension check
    const tryFirefoxExtension = () => {
        if (typeof browser !== "undefined" && browser.runtime) {
            browser.runtime
                .sendMessage(EXTENSION_ID.firefox, { action: "ping" })
                .then(response => {
                    if (response && response.success) {
                        setExtensionAvailable(true);
                    } else {
                        setExtensionAvailable(false);
                    }
                })
                .catch(() => {
                    setExtensionAvailable(false);
                });
        } else {
            setExtensionAvailable(false);
        }
    };

    // Check if extension is available
    const checkExtensionAvailability = async () => {
        try {
            // Try Chrome extension first
            if (typeof chrome !== "undefined" && chrome.runtime) {
                chrome.runtime.sendMessage(EXTENSION_ID.chrome, { action: "ping" }, response => {
                    console.log("Chrome extension response:", response);
                    if (chrome.runtime.lastError) {
                        // Try Firefox extension
                        tryFirefoxExtension();
                    } else if (response && response.success) {
                        setExtensionAvailable(true);
                    } else {
                        setExtensionAvailable(false);
                    }
                });
            } else if (typeof browser !== "undefined" && browser.runtime) {
                // Try Firefox directly
                tryFirefoxExtension();
            } else {
                setExtensionAvailable(false);
            }
        } catch (error) {
            console.error("Error checking extension availability:", error);
            setExtensionAvailable(false);
        }
    };

    return { extensionAvailable, extractMetadataWithExtension };
};

// Extract metadata using the extension
const extractMetadataWithExtension = async (url: string) => {
    return new Promise((resolve, reject) => {
        if (!url || !isValidHttpUrl(url)) {
            reject(new Error("Please enter a valid URL"));
            return;
        }

        const isTwitterUrl = url.includes("twitter.com") || url.includes("t.co") || url.includes("x.com");
        const action = isTwitterUrl ? "extractTweet" : "extractMetadataRaw";

        // Handle response from extension
        const handleExtensionResponse = (response: any) => {
            if (response && response.success && response.data) {
                if (isTwitterUrl) {
                }
                resolve(response.data);
            } else {
                reject(new Error(response?.error || "Unknown error"));
            }
        };

        console.log("Extracting metadata with extension:", action);
        try {
            // Try Chrome first
            if (typeof chrome !== "undefined" && chrome.runtime) {
                chrome.runtime.sendMessage(EXTENSION_ID.chrome, { action, url }, handleExtensionResponse);
            } else if (typeof browser !== "undefined" && browser.runtime) {
                // Try Firefox
                browser.runtime.sendMessage(EXTENSION_ID.firefox, { action: "extractMetadataRaw", url }).then(handleExtensionResponse);
            } else {
                reject(new Error("Browser extension API not available"));
            }
        } catch (error) {
            reject(error);
        }
    });
};
