import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Category from "../components/category.js";
import BreakingNews from "../components/breakingNews.js";
import { X2AdminProvider } from "./extension/context/useX2AdminContext";
// import SearchPublisher from "../components/searchPublisher.js";

function Categories({ categories, onCategoryUpdate }) {
    const location = useLocation();
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        if (location.pathname === "/") {
            setSelectedCategory(categories?.find(c => c.name.toLowerCase() === "trending" || c.name.toLowerCase() === "news") || categories[0]);
        } else {
            if (location.pathname.indexOf("category") > -1) {
                const categoryNameInURL = location.pathname.replace("/category/", "").toLowerCase();
                const landedOnCategory = categories.find(category => category.name.toLowerCase() === categoryNameInURL);
                if (landedOnCategory) {
                    setSelectedCategory(landedOnCategory);
                } else {
                    setSelectedCategory(categories[0]);
                }
            }
        }
    }, [categories, location.pathname]);

    return (
        <X2AdminProvider>
            <div className="main-container bg-light">
                {categories.length && selectedCategory ? (
                    <Category key={selectedCategory.id} category={selectedCategory} onCategoryUpdate={onCategoryUpdate} categories={categories} />
                ) : null}
                {categories.length && selectedCategory ? <BreakingNews key="breaking_news" categoryName={selectedCategory.name} /> : null}
                {/* {categories.length ? <SearchPublisher key="publisher_search" categoryName={selectedCategory} user={user} /> : null} */}
            </div>
        </X2AdminProvider>
    );
}

Categories.propTypes = {
    categories: PropTypes.array.isRequired,
    onCategoryUpdate: PropTypes.func.isRequired
};

export default Categories;
