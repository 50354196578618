import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ReactDOM from "react-dom";
import { useAuth } from "react-oidc-context";
import { BACKEND_URL } from "../config.js";
import { getAuthHeaders, sanitizeHtmlAndConvertToBase64 } from "../utils/utils.js";
import { CategoryAccessProvider } from "./contexts/categoryContext";
import NewStory from "./topic/newStory.js";
import Topic from "./topic/topic.js";
import PaginationBar from "./utils/pagination.js";
import { useHasX2AdminExtension } from "./extension/hooks/useHasX2AdminExtension";
import { useX2AdminContext } from "./extension/context/useX2AdminContext";

// axios.defaults.headers['ngrok-skip-browser-warning'] = '1';

const DEFAULT_TOPICS_PER_PAGE = 5;
const DEFAULT_FILTER = "all";

function Category({ category, onCategoryUpdate, categories }) {
    const { extractMetadataWithExtension } = useHasX2AdminExtension();
    const { enableX2Admin } = useX2AdminContext();

    const alert = useAlert();
    const { user } = useAuth();
    const [topics, setTopics] = useState(null);
    const [totalTopics, setTotalTopics] = useState(0);
    const [newTopicMode, setNewStoryMode] = useState(false);
    const [forceRender, setForceRender] = useState(0);
    const [isFetching, setIfFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(() => {
        const customSetting = localStorage.getItem("TOPICS_PER_PAGE");
        return parseInt(customSetting) || DEFAULT_TOPICS_PER_PAGE;
    });
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("TOPICS_FILTER_V2");
        return savedFilter || DEFAULT_FILTER;
    });

    const fetchTopics = useCallback(async () => {
        try {
            setIfFetching(true);
            const headers = getAuthHeaders(user.access_token);
            const result = await axios.get(`${BACKEND_URL}/admin/category/linked-stories/${category.id}`, {
                params: { filter, page, perPage },
                headers
            });
            setTopics(result.data.data);
            setTotalTopics(result.data.total);
            onCategoryUpdate(category.id, { ...category, totalTopics: result.data.total });
        } catch (e) {
            alert.show(e.toString());
        } finally {
            setIfFetching(false);
        }
    }, [alert, category, filter, onCategoryUpdate, page, perPage, user?.access_token]);

    useEffect(() => {
        if (category && category.id) {
            fetchTopics();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, category.name, filter, page, perPage]);

    const createStory = useCallback(
        async topic => {
            try {
                const headers = getAuthHeaders(user.access_token);
                const body = {
                    topic_id: category.id,
                    story: topic
                };
                await axios.post(`${BACKEND_URL}/admin/story`, body, {
                    headers
                });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, category.id, fetchTopics, user.access_token]
    );

    const onPerPageChange = useCallback(e => {
        const perPageNum = parseInt(e.target.value);
        localStorage.setItem("TOPICS_PER_PAGE", perPageNum);
        setPerPage(perPageNum);
    }, []);

    const onFilterChange = useCallback(e => {
        localStorage.setItem("TOPICS_FILTER_V2", e.target.value);
        setFilter(e.target.value);
    }, []);

    const updateTopic = useCallback(
        async (topicId, topicFieldsToUpdate) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.patch(`${BACKEND_URL}/admin/story/${topicId}`, topicFieldsToUpdate, { headers });
                await fetchTopics();
            } catch (e) {
                throw e;
            }
        },
        [fetchTopics, user.access_token]
    );

    const relinkStoryToCategory = useCallback(
        async (storyId, topicId) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.post(`${BACKEND_URL}/admin/story/relink`, { story_id: storyId, topic_id: topicId }, { headers });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const changeStoryPosition = useCallback(
        async (storyId1, storyId2) => {
            const headers = getAuthHeaders(user.access_token);
            const body = {
                topic_id: category.id,
                story_id_1: storyId1,
                story_id_2: storyId2
            };
            await axios.post(`${BACKEND_URL}/admin/story/reorder`, body, {
                headers
            });
            return fetchTopics();
        },
        [category.id, fetchTopics, user.access_token]
    );

    const pinArticle = useCallback(
        async (story_id, data) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                const payload = { story_id, url: data.url, article_id: data.article_id, tweet: data.tweet, rawData: data.rawData };

                if (enableX2Admin) {
                    try {
                        const extractedData = await extractMetadataWithExtension(payload.url);
                        console.log("extension extractedData:", extractedData);
                        if (extractedData?.html) {
                            payload.rawData = sanitizeHtmlAndConvertToBase64(extractedData.html);
                            if (extractedData?.response?.headers) {
                                payload.sourceHeaders = extractedData.response.headers;
                            }
                        }
                    } catch (e) {
                        console.error(`saveNewBreakingNewsContent: ${e}`);
                    }
                }

                await axios.post(`${BACKEND_URL}/admin/story/pin/article`, payload, { headers });
                await fetchTopics();
            } catch (e) {
                const responseError = (e && e.response && e.response.data && e.response.data.message) || "Unexpected error";
                alert.show(responseError || e);
            }
        },
        [alert, fetchTopics, user.access_token, enableX2Admin, extractMetadataWithExtension]
    );

    const pinTweet = useCallback(
        async (storyId, data) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                const validateStatus = status => status === 200 || status === 201 || status === 500;

                const dataToBeSent = {
                    storyId,
                    url: data.tweet ? undefined : data.url,
                    tweet: data.tweet
                };

                if (enableX2Admin && !dataToBeSent.tweet) {
                    try {
                        const extractedData = await extractMetadataWithExtension(data.url);
                        if (extractedData?.tweet) {
                            dataToBeSent.tweet = extractedData.tweet;
                            dataToBeSent.url = undefined;
                        }
                        console.log("extension extractedData:", extractedData);
                    } catch (e) {
                        console.error(`saveNewBreakingNewsContent: ${e}`);
                    }
                }

                const result = await axios.post(`${BACKEND_URL}/admin/content/tweet`, dataToBeSent, { headers, validateStatus });
                if (result.status === 500 && result.data && result.data.message) {
                    setForceRender(forceRender + 1);
                    return;
                }
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, forceRender, user.access_token, extractMetadataWithExtension, enableX2Admin]
    );

    const updatePinnedArticle = useCallback(
        async (topicId, articleId, data) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                const body = {
                    data: { ...data },
                    topic_id: topicId,
                    article_id: articleId
                };
                await axios.patch(`${BACKEND_URL}/admin/story/pin/article`, body, {
                    headers
                });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const updatePinnedTweet = useCallback(
        async (tweet_id, data) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                const body = {
                    data,
                    tweet_id
                };
                await axios.patch(`${BACKEND_URL}/admin/content/tweet`, body, {
                    headers
                });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const unpinArticle = useCallback(
        async (story_id, article_id) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.delete(`${BACKEND_URL}/admin/story/pin/article`, {
                    headers,
                    data: { story_id, article_id }
                });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const unpinTweet = useCallback(
        async (story_id, tweet_id) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.delete(`${BACKEND_URL}/admin/story/pin/tweet`, {
                    headers,
                    data: { story_id, tweet_id }
                });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const addArticleToBanlist = useCallback(
        async (story_id, article_id) => {
            console.log("article_id: ", article_id);
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.post(`${BACKEND_URL}/admin/story/ban/article`, { story_id, article_id }, { headers });
                fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const removeArticleFromBanlist = useCallback(
        async (story_id, article_id) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.delete(`${BACKEND_URL}/admin/story/ban/article`, {
                    headers,
                    data: { story_id, article_id }
                });
                await fetchTopics();
            } catch (e) {
                alert.show(e.toString());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const changeStoryOrder = useCallback(
        async (storyId1, direction) => {
            // in the new backend service "topic" is now "story" and "category" is "topic"
            const currentIndex = topics.findIndex(story => story.id === storyId1);
            const updatedIndex = currentIndex + (direction === "up" ? -1 : 1);
            const storyId2 = topics[updatedIndex].id;

            if (direction === "up") {
                // the first id passed will be moved down
                return changeStoryPosition(storyId2, storyId1);
            }
            return changeStoryPosition(storyId1, storyId2);
        },
        [changeStoryPosition, topics]
    );

    const toggleNewTopicMode = useCallback(() => {
        setNewStoryMode(!newTopicMode);
    }, [newTopicMode]);

    const saveNewStory = useCallback(
        newTopic => {
            ReactDOM.unstable_batchedUpdates(() => {
                setNewStoryMode(false);
                setPage(1);
                createStory(newTopic);
            });
        },
        [createStory]
    );

    return (
        <main className="main-container-body pt-lg-2 px-lg-5" data-cid="AddStoryContent">
            <CategoryAccessProvider fetchCategory={fetchTopics}>
                <h4>
                    Stories under <span className="font-weight-bold text-info">{category.name}</span> category
                    {!isFetching ? (
                        <span onClick={fetchTopics} className="mx-3 pointer">
                            <i className="fas fa-sync-alt"></i>
                        </span>
                    ) : (
                        <span className="mx-3 pointer">
                            <i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
                        </span>
                    )}
                    {!newTopicMode ? (
                        <div onClick={toggleNewTopicMode} className="ml-2 d-inline pointer">
                            <span className="">
                                <i className="fas fa-plus"></i>
                            </span>
                        </div>
                    ) : null}
                </h4>
                {newTopicMode ? <NewStory onClose={toggleNewTopicMode} onSave={saveNewStory} /> : null}
                <fieldset className="d-flex form-group p-0 m-0 mb-2">
                    <div className="form-check d-inline">
                        <label className="form-check-label">
                            <input
                                onChange={onFilterChange}
                                type="radio"
                                className="form-check-input"
                                name="all"
                                value="all"
                                checked={filter === "all"}
                                data-testid="radio-published-draft"
                            />
                            Published OR Draft
                        </label>
                    </div>
                    <div className="form-check d-inline ml-3">
                        <label className="form-check-label">
                            <input
                                onChange={onFilterChange}
                                type="radio"
                                className="form-check-input"
                                name="published"
                                value="published"
                                checked={filter === "published"}
                                data-testid="radio-published"
                            />
                            Published
                        </label>
                    </div>
                    <div className="form-check d-inline ml-3">
                        <label className="form-check-label">
                            <input
                                onChange={onFilterChange}
                                type="radio"
                                className="form-check-input"
                                name="draft"
                                value="draft"
                                checked={filter === "draft"}
                                data-testid="radio-draft"
                            />
                            Draft
                        </label>
                    </div>
                    {/* <div className="form-check d-inline ml-3">
                        <label className="form-check-label">
                            <input
                                onChange={onFilterChange}
                                type="radio"
                                className="form-check-input"
                                name="unlinked"
                                value="unlinked"
                                checked={filter === "unlinked"}
                            />
                            Unlinked
                        </label>
                    </div> */}
                </fieldset>
                {topics && topics.length ? (
                    <PaginationBar
                        className="mb-2"
                        total={totalTopics}
                        page={page}
                        perPage={perPage}
                        onClick={selectedPage => setPage(selectedPage)}
                        onPerPageChange={onPerPageChange}
                    />
                ) : null}
                {topics && topics.length
                    ? topics.map((topic, i, array) => {
                          return (
                              <Topic
                                  categoryType={category.type || ""}
                                  changeTopicOrder={changeStoryOrder}
                                  updateTopic={updateTopic}
                                  topicsTotal={array.length}
                                  isFirstTopic={page === 1 && i === 0 ? true : false}
                                  isLastTopic={topics[topics.length - 1].id === topic.id ? true : false}
                                  topicIndex={i}
                                  key={topic.id}
                                  topic={topic}
                                  categoryName={category.name}
                                  categoryId={category.id}
                                  pinArticle={pinArticle}
                                  pinTweet={pinTweet}
                                  updatePinnedArticle={updatePinnedArticle}
                                  updatePinnedTweet={updatePinnedTweet}
                                  unpinArticle={unpinArticle}
                                  unpinTweet={unpinTweet}
                                  addArticleToBanlist={addArticleToBanlist}
                                  removeArticleFromBanlist={removeArticleFromBanlist}
                                  relinkStoryToCategory={relinkStoryToCategory}
                                  categories={categories}
                                  refetchStories={fetchTopics}
                              />
                          );
                      })
                    : null}
                {topics && topics.length ? (
                    <PaginationBar
                        total={totalTopics}
                        page={page}
                        perPage={perPage}
                        onClick={selectedPage => setPage(selectedPage)}
                        onPerPageChange={onPerPageChange}
                    />
                ) : null}
            </CategoryAccessProvider>
        </main>
    );
}

Category.propTypes = {
    category: PropTypes.object.isRequired,
    onCategoryUpdate: PropTypes.func.isRequired,
    categories: PropTypes.array // only used to pass down to the Topic component
};

export default Category;
