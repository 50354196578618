import { createContext, ReactNode, useCallback, useContext, useState } from "react";

export const X2AdminContext = createContext({
    enableX2Admin: true,
    setEnableX2Admin: (val: boolean) => {}
});

type GeneralProviderProps = {
    children: ReactNode;
};

const STORAGE_KEY = "--app:metadata_extractor:enabled";

export const X2AdminProvider = ({ children }: GeneralProviderProps) => {
    const [enableX2Admin, setEnableX2Admin] = useState<boolean>(() =>
        localStorage.getItem(STORAGE_KEY) === null ? true : localStorage.getItem(STORAGE_KEY) === "true"
    );

    return (
        <X2AdminContext.Provider
            value={{
                enableX2Admin,
                setEnableX2Admin: useCallback((value: boolean) => {
                    setEnableX2Admin(value);
                    localStorage.setItem(STORAGE_KEY, value.toString());
                }, [])
            }}
        >
            {children}
        </X2AdminContext.Provider>
    );
};

export const useX2AdminContext = () => {
    return useContext(X2AdminContext);
};
