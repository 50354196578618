import { ChangeEvent, FC, useMemo } from "react";
import { useHasX2AdminExtension } from "../hooks/useHasX2AdminExtension";
import { useX2AdminContext } from "../context/useX2AdminContext";
import { nanoid } from "nanoid";

interface ExtensionTogglerProps {}

export const ExtensionToggler: FC<ExtensionTogglerProps> = () => {
    const { extensionAvailable } = useHasX2AdminExtension();
    const { enableX2Admin, setEnableX2Admin } = useX2AdminContext();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("e.target.checked", e.target.checked);
        console.log("enableX2Admin", enableX2Admin);
        setEnableX2Admin(e.target.checked);
    };

    const inputID = useMemo(() => nanoid(), []);

    return (
        <div className="metadata-extractor w-100 mt-2">
            {extensionAvailable !== false && (
                <div className="metadata-toggle-container mt-2 w-100">
                    <div className="custom-control custom-switch">
                        <input onChange={onChange} type="checkbox" className="custom-control-input" id={inputID} checked={enableX2Admin} />
                        <label className="custom-control-label pointer" htmlFor={inputID}>
                            {typeof extensionAvailable === "boolean" ? `Use x2Admin extension` : "Looking for x2Admin extension..."}
                        </label>
                    </div>
                </div>
            )}
            {extensionAvailable === false && (
                <div className="extension-notice">
                    <div className="alert alert-dismissible alert-warning mt-2 mb-0 w-100 p-2">
                        <ul className="p-0 m-0">The x2admin extension is not installed.</ul>
                    </div>
                </div>
            )}
        </div>
    );
};
